import React, { Fragment } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { Popover, Transition } from "@headlessui/react"
import {
  DuplicateIcon,
  ShareIcon,
  MenuIcon,
  DatabaseIcon,
  XIcon,
  MailIcon,
} from "@heroicons/react/outline"
import { ChevronDownIcon } from "@heroicons/react/solid"
import Logo from "../img/logo.inline.svg"
import { CmsExpertiseNode } from "../types/cms"

export const icons: {
  [key: string]: any
} = {
  database: DatabaseIcon,
  mail: MailIcon,
  duplicate: DuplicateIcon,
  share: ShareIcon,
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ")
}

export default function Header() {
  const { expertise } = useStaticQuery(graphql`
    query MyHeaderQuery {
      expertise: allContentfulExpertise {
        edges {
          node {
            title
            slug
            intro
            icon
          }
        }
      }
    }
  `)

  return (
    <Popover className="relative bg-slate-800 z-50">
      <div className="flex justify-between items-center px-4 py-6 sm:px-6 md:justify-start md:space-x-10 container">
        <div>
          <Link to="/" className="flex hover:opacity-75">
            <span className="sr-only">Coolwine-ICT</span>
            <Logo className="h-8 w-auto sm:h-14" />
          </Link>
        </div>
        <div className="-mr-2 -my-2 md:hidden">
          <Popover.Button className="bg-slate-900 rounded-md p-2 inline-flex items-center justify-center text-gray-50 hover:text-gray-500 hover:bg-cyan-800 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
            <span className="sr-only">Open menu</span>
            <MenuIcon className="h-6 w-6" aria-hidden="true" />
          </Popover.Button>
        </div>
        <div className="hidden md:flex-1 md:flex md:items-center md:justify-between">
          <Popover.Group as="nav" className="flex space-x-10">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={classNames(
                      open ? "text-cyan-900" : "text-white",
                      "group inline-flex items-center text-base menu-item"
                    )}
                  >
                    <span className="font-sans">Expertises</span>
                    <ChevronDownIcon
                      className={classNames(
                        open ? "text-gray-600" : "text-gray-400",
                        "ml-2 h-5 w-5 group-hover:text-gray-500"
                      )}
                      aria-hidden="true"
                    />
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute z-10 -ml-4 mt-3 transform w-screen max-w-md lg:max-w-3xl">
                      <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                        <div className="relative grid gap-6 bg-slate-700 px-5 py-6 sm:gap-8 sm:p-8 lg:grid-cols-2">
                          {expertise.edges.map((item: CmsExpertiseNode) => {
                            const Icon = icons[item.node.icon[0]]

                            return (
                              <Link
                                key={item.node.slug}
                                to={`/${item.node.slug}`}
                                className="-m-3 p-3 flex items-start rounded-lg hover:bg-slate-800"
                              >
                                <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-cyan-800 text-white sm:h-12 sm:w-12">
                                  <Icon
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                </div>
                                <div className="ml-4">
                                  <p className="text-base font-medium text-white">
                                    {item.node.title}
                                  </p>
                                  <p className="mt-1 !text-sm subtext">
                                    {item.node.intro}.
                                  </p>
                                </div>
                              </Link>
                            )
                          })}
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
            <Link to="/over-ons" className="menu-item">
              Over ons
            </Link>
            <Link to="/partners" className="menu-item">
              Partners
            </Link>
            <Link to="/contact" className="menu-item">
              Contact
            </Link>
          </Popover.Group>
        </div>

        <Link to="/contact" className="button-default lg:!flex !hidden">
          Contact
        </Link>
      </div>

      <Transition
        as={Fragment}
        enter="duration-200 ease-out"
        enterFrom="opacity-0 scale-95"
        enterTo="opacity-100 scale-100"
        leave="duration-100 ease-in"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-95"
      >
        <Popover.Panel
          focus
          className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
        >
          <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-slate-700 divide-y-2 divide-gray-50">
            <div className="pt-5 pb-6 px-5">
              <div className="flex items-center justify-between">
                <div>
                  <Logo className="h-8 w-auto sm:h-14" />
                </div>
                <div className="-mr-2">
                  <Popover.Button className="bg-slate-800 rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500">
                    <span className="sr-only">Sluit menu</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="mt-6">
                <nav className="grid gap-6">
                  {expertise.edges.map((item: CmsExpertiseNode) => {
                    const Icon = icons[item.node.icon[0]]

                    return (
                      <Link
                        key={item.node.slug}
                        to={`/${item.node.slug}`}
                        className="-m-3 p-3 flex items-center rounded-lg hover:bg-slate-900"
                      >
                        <div className="flex-shrink-0 flex items-center justify-center h-10 w-10 rounded-md bg-cyan-500 text-white">
                          <Icon className="h-6 w-6" aria-hidden="true" />
                        </div>
                        <div className="ml-4 text-base font-medium text-white">
                          {item.node.title}
                        </div>
                      </Link>
                    )
                  })}
                </nav>
              </div>
            </div>
            <div className="py-6 px-5">
              <div className="grid grid-cols-2 gap-4">
                <Link
                  to="/over-ons"
                  className="text-base font-medium text-white"
                >
                  Over ons
                </Link>
                <Link
                  to="/partners"
                  className="text-base font-medium text-white"
                >
                  Referenties
                </Link>
                <Link
                  to="/contact"
                  className="text-base font-medium text-white"
                >
                  Contact
                </Link>
              </div>
            </div>
          </div>
        </Popover.Panel>
      </Transition>
    </Popover>
  )
}
