import React from "react"
import { Link } from "gatsby"

const navigation = {
  main: [
    { name: "Home", href: "/" },
    { name: "Partners", href: "/partners" },
    { name: "Over ons", href: "/over-ons" },
    { name: "Contact", href: "/contact" },
  ],
}

export default function Footer() {
  return (
    <footer className="bg-slate-800">
      <div className="max-w-7xl mx-auto py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav
          className="-mx-5 -my-2 flex flex-wrap justify-center"
          aria-label="Footer"
        >
          {navigation.main.map((item) => (
            <div key={item.name} className="px-5 py-2">
              <Link to={item.href} className="menu-item">
                {item.name}
              </Link>
            </div>
          ))}
        </nav>
        <p className="mt-8 text-center text-base font-serif text-gray-400">
          &copy; {new Date().getFullYear()} Coolwine ICT, Inc. Alle rechten
          voorbehouden.
        </p>
      </div>
    </footer>
  )
}
